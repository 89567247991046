import CreditCardIcon from '@mui/icons-material/CreditCard'
import { Roles } from '../roles'
import CreditScoreIcon from '@mui/icons-material/CreditScore'
import { lazyImport } from '../../hooks/LazyImport'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined'
import CableIcon from '@mui/icons-material/Cable'

const { CreateCouponPage } = lazyImport(
  () => import('src/pages/dashboard/CreateCoupon'),
  'CreateCouponPage'
)

const { ApplyCouponPage } = lazyImport(
  () => import('src/pages/dashboard/ApplyCoupon'),
  'ApplyCouponPage'
)

const { ApplyRefundPage } = lazyImport(
  () => import('src/pages/dashboard/ApplyRefund'),
  'ApplyRefundPage'
)

const { WireTransferPage } = lazyImport(
  () => import('src/pages/dashboard/WireTransfer'),
  'WireTransferPage'
)

const { BankTransferPage } = lazyImport(
  () => import('src/pages/dashboard/BankTransfer'),
  'BankTransferPage'
)

const { OveragesPage } = lazyImport(() => import('src/pages/dashboard/Overages'), 'OveragesPage')
const { ChangePaymentCyclePage } = lazyImport(
  () => import('src/pages/dashboard/ChangePaymentCycle'),
  'ChangePaymentCyclePage'
)

export const BillingRoutes = [
  {
    name: 'Wire Transfer',
    icon: <CableIcon />,
    route: '/dashboard/wire-transfer',
    path: 'wire-transfer/*',
    element: <WireTransferPage />,
    permissions: [Roles['cheq-admin-t4'], Roles['team-leader']],
  },
  {
    name: 'Bank Transfer',
    icon: <AccountBalanceOutlinedIcon />,
    route: '/dashboard/bank-transfer',
    path: 'bank-transfer/*',
    element: <BankTransferPage />,
    permissions: [Roles['cheq-admin-t4'], Roles['team-leader']],
  },
  {
    name: 'Apply Refund',
    icon: <CurrencyExchangeIcon />,
    route: '/dashboard/apply-refund',
    path: 'apply-refund/*',
    element: <ApplyRefundPage />,
    permissions: [Roles['cheq-admin-t2'], Roles['cheq-admin-t4'], Roles['team-leader']],
  },
  {
    name: 'Create Coupon',
    icon: <CreditCardIcon />,
    route: '/dashboard/create-coupon',
    path: 'create-coupon/*',
    element: <CreateCouponPage />,
    permissions: [
      Roles['marketing'],
      Roles['sales'],
      Roles['cheq-admin-t2'],
      Roles['team-leader'],
      Roles['cheq-admin-t4'],
    ],
  },
  {
    name: 'Apply Coupon',
    icon: <CreditScoreIcon />,
    route: '/dashboard/apply-coupon',
    path: 'apply-coupon/*',
    element: <ApplyCouponPage />,
    permissions: [
      Roles['marketing'],
      Roles['sales'],
      Roles['cheq-admin-t2'],
      Roles['team-leader'],
      Roles['cheq-admin-t4'],
    ],
  },
  {
    name: 'Overages',
    icon: <CreditScoreIcon />,
    route: '/dashboard/overages',
    path: 'overages/*',
    element: <OveragesPage />,
    permissions: [Roles['cheq-admin-t2']],
  },
  {
    name: 'Change Payment Cycle',
    icon: <CreditScoreIcon />,
    route: '/dashboard/change-payment-cycle',
    path: 'change-payment-cycle/*',
    element: <ChangePaymentCyclePage />,
    permissions: [Roles['cheq-admin-t2'], Roles['team-leader'], Roles['sales']],
  },
]
